import { u } from 'umbrellajs'

function attach (inputField) {
  const $inputField = u(inputField)

  const handler = e => {
    const $parent = u(e.target).parent('label')
    $parent.toggleClass('is-active')
  }

  $inputField.on('focus', handler)
  $inputField.on('blur', handler)
}

export function init () {
  const contactForm = u('#contact-form')
  const inputFields = contactForm.find('input')
  const textarea = contactForm.find('textarea')

  inputFields.nodes.forEach(attach)
  textarea.nodes.forEach(attach)
}

export default init
