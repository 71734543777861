import { u } from 'umbrellajs'

/*

 Student/teacher toggle

 */


const studentTeacherToggle = u('#student-teacher-toggle')
const studentFeatures = u('#student-features')
const teacherFeatures = u('#teacher-features')

studentTeacherToggle.on('toggle', (e, value) => {
  if (value === 'teacher') {
    teacherFeatures.removeClass('display-none')
    studentFeatures.addClass('display-none')
  } else {
    teacherFeatures.addClass('display-none')
    studentFeatures.removeClass('display-none')
  }
})


/*

 Form flash

 */

const formFlash = u('#form-flash')


function getParameterByName(name, url) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, "\\$&")
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
  var results = regex.exec(url)

  if (!results) return null
  if (!results[2]) return ''

  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

if (getParameterByName('message')) {
  formFlash.addClass('show')

  setTimeout(() => {
    formFlash.removeClass('show')
  }, 5000)
}


/*

 Set animation depending on screen size

 */


const container = u('#animation-container')

setTimeout(() => {
  addAnimation ()
}, 1000)

u('#cursor').addClass('animate')

var largeAnimation = false

function addAnimation () {
  if (window.innerWidth > 1200) {
    container.html('<iframe src="/animations/desktop/animation.html" scrolling="no" frameborder="0" height="900" width="650">')
    largeAnimation = true
  }
  else container.html('<iframe src="/animations/mobile/animation.html" scrolling="no" frameborder="0" height="550" width="310">')
}

window.addEventListener('resize', () => {
  if ((window.innerWidth > 1200 && !largeAnimation) || (window.innerWidth <= 1200 && largeAnimation)) {
    addAnimation()
    largeAnimation = window.innerWidth > 1200
  }
});
