import { u } from 'umbrellajs'


/*

 Typewriter effect

 */


var captionLength = 0;
var caption = '';
var captionEl = u('#caption');
var value = 0
var texts = ['in groups', 'in projects', 'by doing']

function type() {
  if(value > 2) value = 0

  caption = texts[value]

  if (captionLength < caption.length + 1) {
    setTimeout(() => {
      captionEl.html(caption.substr(0, captionLength++))
      type()
    }, 140)
  } else {
    u('#cursor').addClass('animate')
    setTimeout(() => {
      erase()
      u('#cursor').removeClass('animate')
    }, 5000)
  }
}

function erase() {
  if (captionLength >= 0) {
    setTimeout(() => {
      captionEl.html(caption.substr(0, captionLength--))
      erase()
    }, 140)
  } else {
    value = value + 1
    type()
  }
}


type()
