/*
TODO: on dom ready
*/

import { u } from 'umbrellajs'
import features from './features'
import home from './home'
import { init as initContact } from './contact'
import SiteNav from '../_modules/site-nav/site-nav'
const siteNav = new SiteNav().init(u('#mobile-nav-toggle'), u('#mobile-nav'))

/*

Initialize modules

*/

// on dom ready
// if page === contact
initContact()

/*

Toggles

*/

const toggles = u('[data-toggle]').nodes

toggles.forEach(toggle => {
  const $toggle = u(toggle)
  const children = $toggle.children().nodes

  function isSelected (node) {
    return u(node).hasClass('is-selected')
  }

  function addListener (child) {
    u(child).on('click', e => {
      const $el = u(e.currentTarget)
      $toggle.trigger('toggle', $el.data('toggle-value'))

      children
        .filter(isSelected)
        .forEach(c => u(c).removeClass('is-selected'))

      $el.addClass('is-selected')
    })
  }

  children.forEach(addListener)
})
