class SiteNav {
  init (toggle, nav) {
    this.toggle = toggle
    this.nav = nav

    this.initEventListeners()

    return this
  }

  initEventListeners () {
    this.toggle.on('click', this.toggleNav.bind(this))
  }

  toggleNav () {
    this.nav.toggleClass('is-hidden')
    this.toggle.toggleClass('is-active')
  }
}

export default SiteNav
